import { Box, Button, Center, Container, Flex, Image, Stack, Text } from "@mantine/core";
import type { InferGetServerSidePropsType } from "next";
import { getCsrfToken, getProviders, getSession, signIn, useSession } from "next-auth/react";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { FooterLayout } from "../../components/layouts/Footer.layout";
import { rootPath } from "../../utils/appConfig";

export default function SignIn({ csrfToken, providers }: InferGetServerSidePropsType<typeof getServerSideProps>) {
	const { data: session } = useSession(); // Always call hooks outside conditionals
	const router = useRouter();

	// Handle session redirect first (without breaking hook order)
	if (session) {
		router.push(`${rootPath}/`);
		return null;
	}

	// Render the content conditionally, but the hooks are already called outside the conditional.
	if (!providers) {
		return (
			<Stack>
				<Center>
					<Text>Sign in is not available</Text>
				</Center>
			</Stack>
		);
	}

	return (
		<>
			<NextSeo title="Bahagia.day | Login" description="a moment platform" />

			<Container sx={{ maxWidth: "500px" }}>
				<Center style={{ height: "100svh" }}>
					<Stack align="center" spacing={32} w={"100%"} my={24}>
						<Stack justify="center" spacing={10}>
							<Flex justify={"center"} align={"center"} gap={8}>
								<Box w={"100%"} maw={160}>
									<Image src="/image/bahagia-logo-typeface.svg" alt="empty wedding" />
								</Box>
							</Flex>
							<Text size={13} weight={400} align="center">
								Bahagia for Business, Silahkan Masuk
							</Text>
						</Stack>

						<Stack justify="center" align={"center"} spacing={24} w={"85%"} sx={{ maxWidth: "300px" }}>
							<Stack spacing={10} w={"100%"}>
								<Stack spacing={10}>
									<button
										onClick={(e) => {
											e.preventDefault();
											signIn("google");
										}}
										className="gsi-material-button"
									>
										<div className="gsi-material-button-state"></div>
										<div className="gsi-material-button-content-wrapper">
											<div className="gsi-material-button-icon">
												<svg
													version="1.1"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 48 48"
													style={{ display: "block" }}
												>
													<path
														fill="#EA4335"
														d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
													></path>
													<path
														fill="#4285F4"
														d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
													></path>
													<path
														fill="#FBBC05"
														d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
													></path>
													<path
														fill="#34A853"
														d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
													></path>
													<path fill="none" d="M0 0h48v48H0z"></path>
												</svg>
											</div>
											<span className="gsi-material-button-contents">Masuk dengan Google</span>
											<span style={{ display: "none" }}>Masuk dengan Google</span>
										</div>
									</button>
								</Stack>
								<Stack spacing={16} mt={6} w={"100%"}>
									<Button
										rel="noopener noreferrer"
										leftIcon={<Text className="ri-mail-line" size={"lg"}></Text>}
										component="a"
										href="/auth/email/signin"
										variant="filled"
										color="pink"
										radius={"md"}
										fullWidth
										sx={{ ".mantine-Button-label": { width: "100%!important", justifyContent: "center!important" } }}
									>
										Masuk dengan Email
									</Button>
									<Button
										rel="noopener noreferrer"
										leftIcon={<Text className="ri-mail-line" size={"lg"}></Text>}
										component="a"
										href="/auth/email/signup"
										variant="outline"
										color="pink"
										radius={"md"}
										fullWidth
										sx={{ ".mantine-Button-label": { width: "100%!important", justifyContent: "center!important" } }}
									>
										Daftar dengan Email
									</Button>
								</Stack>
							</Stack>
						</Stack>

						<Text align="center" color="dark" size={12}>
							Dengan login dan mendaftar, berarti kamu telah setuju dengan{" "}
							<Text component="a" weight={500} color="pink" href="https://bahagia.day/company/privacy">
								kebijakan privasi
							</Text>{" "}
							dan{" "}
							<Text component="a" weight={500} color="pink" href="https://bahagia.day/company/terms">
								kebijakan layanan
							</Text>{" "}
							kami
						</Text>

						<Stack spacing="xl">
							<FooterLayout />
						</Stack>
					</Stack>
				</Center>
			</Container>
		</>
	);
}

export async function getServerSideProps(context) {
	const providers = await getProviders();
	const session = await getSession(context);

	if (session)
		return {
			redirect: { destination: `${rootPath}/`, permanent: false },
		};

	return {
		props: { providers, csrfToken: await getCsrfToken(context) },
	};
}
