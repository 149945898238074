import { useMediaQuery } from "@mantine/hooks";

export const signinPath = "/auth/signin";
export const rootPath = "/";
export const baseAPIUrl = process.env.NEXT_PUBLIC_API_URL;
export const paymentAPIUrl = process.env.NEXT_PUBLIC_MIDTRANS_API;
export const baseUrl = process.env.NEXT_PUBLIC_URL;
export const originURL = process.env.NEXT_DOMAIN;
export const weddingPath = "wd";
export const imagePlaceholder =
	"https://bahagia.s3.ap-southeast-3.amazonaws.com/dev/bahagia_default_image_0f97890872.jpg";
export const momentRate = 19000;
export const adminRate = 3000;
export const weddingDefaultImage = {
	frontCover: 45,
	mainCover: 95,
	secondaryCover: 94,
	tertiaryCover: 96,
};
export const apiConfig = {
	api: {
		bodyParser: true, // Mendukung penguraian data dalam tubuh permintaan
	},
	maxDuration: 25, // This function can run for a maximum of 25 seconds on vercel time limit
};
